import React, { useEffect, useState } from "react";
import i1 from "../images/rotate3d/ooz1es3d_0-min.png";
import i2 from "../images/rotate3d/ooz1es3d_1-min.png";
import i3 from "../images/rotate3d/ooz1es3d_2-min.png";
import i4 from "../images/rotate3d/ooz1es3d_3-min.png";
import i5 from "../images/rotate3d/ooz1es3d_4-min.png";
import i6 from "../images/rotate3d/ooz1es3d_5-min.png";
import i7 from "../images/rotate3d/ooz1es3d_6-min.png";
import i8 from "../images/rotate3d/ooz1es3d_7-min.png";
import i9 from "../images/rotate3d/ooz1es3d_8-min.png";
import i10 from "../images/rotate3d/ooz1es3d_9-min.png";
import i11 from "../images/rotate3d/ooz1es3d_10-min.png";
import i12 from "../images/rotate3d/ooz1es3d_11-min.png";
import i13 from "../images/rotate3d/ooz1es3d_12-min.png";
import i14 from "../images/rotate3d/ooz1es3d_13-min.png";
import i15 from "../images/rotate3d/ooz1es3d_14-min.png";

const images = [
  i1,
  i2,
  i3,
  i4,
  i5,
  i6,
  i7,
  i8,
  i9,
  i10,
  i11,
  i12,
  i13,
  i14,
  i15,
];

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
}

const imIds = ["one", "two", "three", "four", "five", "six", "seven", "eight"];
const im = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

export default function Rotate3D() {
  const [randomIdx, setRandomIdx] = useState([]);
  useEffect(() => {
    shuffle(im);
    setRandomIdx(im);
  }, []);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div id="wrapper" overflow-y="scroll" overscroll-behavior-y="none">
        <div id="image">
          {imIds.map((id, idx) => (
            <div key={idx} id={id} className={`image i${idx + 1}`}>
              <div
                style={{ backgroundImage: `url(${images[randomIdx[idx]]})` }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
