import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Grid } from "semantic-ui-react";

export default function TurtleOozies() {
  return (
    <div className="gallery">
      <br />
      <h1 className="section-header u">TURTLE OOZ1ES</h1>
      <p className="subtitle">
        Find a turtle OOZ1ES with a weapon & pizza in their stomach & get a
        pizza NFT you can exchange for <span className="pink">25 ₳ADA</span> to
        buy a pizza + Ü get to keep the pizza NFT!
      </p>
      <Grid columns={2} stackable centered>
        <Grid.Column>
          <div className="sneak-peak">
            <StaticImage src="../images/turtle/1.png" alt="turtle 1" />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className="sneak-peak">
            <StaticImage src="../images/turtle/2.png" alt="turtle 2" />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className="sneak-peak">
            <StaticImage src="../images/turtle/3.png" alt="turtle 3" />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className="sneak-peak">
            <StaticImage src="../images/turtle/4.png" alt="turtle 4" />
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
}
