import React, { useEffect, useRef, useState } from "react";
import Typed from "typed.js";
import Rotate3D from "./Rotate3D";
import banner from "../images/o_banner.png";
import logo from "../images/o_logo_3d.png";

export default function Header({ onBuyButtonClicked }) {
  const text = useRef(null);
  const typed = useRef(null);
  const [show, setShow] = useState(false);
  useEffect(() => {
    typed.current = new Typed(text.current, {
      strings: [
        'From the creators of <span class="break"></span><a href="https://spacebabez.io" id="sb" target="_blank" rel="noreferrer">Space Babez</a> <em>&amp;</em> <a href="https://swingerz.io" id="sw" target="_blank" rel="noreferrer">Swingerz</a><span class="break"></span>',
      ],
      typeSpeed: 85,
      backSpeed: 35,
      backDelay: 0,
      showCursor: false,
      onComplete: () => {
        setShow(true);
      },
    });
    return () => {
      typed.current?.destroy();
      setShow(false);
    };
  }, []);
  return (
    <>
      <div className="main-header">
        <img src={banner} className="banner" alt="3D OOZ1ES Banner" />
        <h1 className="mt-0 typed-slogan" aria-hidden="true" ref={text} />
        <p className="swing-c" style={{ opacity: show ? 1 : 0 }}>
          <span className="swing">PRESENTS</span>
        </p>
        <div className="header-container">
          <div className="header-left"></div>
          <div className="header-middle">
            <img src={logo} className="logo" alt="3D OOZ1ES Logo" />
            <h1>Ür favorite toilet dwelling creatures are back!</h1>
            <Rotate3D />
            <button className="buyNow" onClick={onBuyButtonClicked}>
              {" "}
            </button>
            <h1>
              <span className="pink">9,999</span> crypto collectibles on the
              Cardano blockchain
            </h1>
          </div>
          <div className="header-right"></div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="squiggly-0">
            <feTurbulence
              id="turbulence"
              baseFrequency="0.02"
              numOctaves="3"
              result="noise"
              seed="0"
            />
            <feDisplacementMap
              id="displacement"
              in="SourceGraphic"
              in2="noise"
              scale="6"
            />
          </filter>
          <filter id="squiggly-1">
            <feTurbulence
              id="turbulence"
              baseFrequency="0.02"
              numOctaves="3"
              result="noise"
              seed="1"
            />
            <feDisplacementMap in="SourceGraphic" in2="noise" scale="8" />
          </filter>
          <filter id="squiggly-2">
            <feTurbulence
              id="turbulence"
              baseFrequency="0.02"
              numOctaves="3"
              result="noise"
              seed="2"
            />
            <feDisplacementMap in="SourceGraphic" in2="noise" scale="6" />
          </filter>
          <filter id="squiggly-3">
            <feTurbulence
              id="turbulence"
              baseFrequency="0.02"
              numOctaves="3"
              result="noise"
              seed="3"
            />
            <feDisplacementMap in="SourceGraphic" in2="noise" scale="8" />
          </filter>
          <filter id="squiggly-4">
            <feTurbulence
              id="turbulence"
              baseFrequency="0.02"
              numOctaves="3"
              result="noise"
              seed="4"
            />
            <feDisplacementMap in="SourceGraphic" in2="noise" scale="6" />
          </filter>
        </defs>
      </svg>
    </>
  );
}
