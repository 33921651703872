import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import colors from "../data/drops/bodyColorDrops.json";

export default function BodyColorRarity({ rarity, onChange }) {
  const [text, setText] = useState("");
  useEffect(() => {
    if (rarity === "") setText("");
  }, [rarity]);
  function handleChange(_, { value }) {
    if (value === "") setText("");
    onChange("bodyColor", value);
  }
  return (
    <Dropdown
      placeholder="Body Color"
      search
      selection
      fluid
      clearable
      basic
      selectOnBlur={false}
      style={{ marginBottom: 15 }}
      options={colors}
      onChange={handleChange}
      value={rarity}
      text={text}
    />
  );
}
