import React from "react";

export default function About() {
  return (
    <>
      <h1 className="section-header">About</h1>
      <p className="details">
        OOZ1ES 3D is the newest NFT created to live forever on the Cardano
        Blockchain. There is a total of <span className="pink">9,999</span>{" "}
        unique OOZ1ES randomly generated from a selection of{" "}
        <span className="pink">251</span> variations in{" "}
        <span className="pink">5</span> rarity categories w/{" "}
        <span className="pink">156,028,055,040</span> billion possible
        combinations.
      </p>
      <p className="details">
        No two OOZ1ES are alike, the more rare a OOZ1ES is the more objects it
        has on or in its body, the score of your OOZ1ES will also be higher!
      </p>
      <p className="details">
        Try your luck to see which one Ü can get {":)"}
      </p>
      <p className="details bubble">
        If you like the stuff{" "}
        <a className="pink" href="https://anti.biz">
          Anti.biz
        </a>{" "}
        creates consider staking with our stake pool @{" "}
        <a
          className="pink"
          href="https://adapools.org/pool/bbd852e7fb67e8f182def1f99d22a51c76b40222ab47d0682223e606"
        >
          ADApools.org
        </a>{" "}
        we will always be a single operated stake pool.
      </p>
    </>
  );
}
