import React from "react";
import { Dropdown } from "semantic-ui-react";
// import data from "../data/drops/nameDrops.json";

const data = Array.from({ length: 9999 }).map((_, i) => ({
  text: "OOZ1ES3D#" + (i + 1),
  key: i + 1,
  value: i + 1,
}));

const SearchInput = ({ selectedOptions = [], onSelected }) => {
  return (
    <Dropdown
      placeholder="Search by Name"
      fluid
      multiple
      selection
      clearable
      lazyLoad
      search
      basic
      options={data}
      value={selectedOptions}
      onChange={onSelected}
      style={{ minHeight: 51, marginBottom: 15 }}
    />
  );
};

export default SearchInput;
