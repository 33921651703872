import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Grid } from "semantic-ui-react";

export default function FrozenOozies() {
  return (
    <div className="gallery fo">
      <br />
      <h1 className="section-header u">FROZEN OOZ1ES</h1>
      <p className="subtitle">
        Find 1 of the 4 frozen OOZ1ES and win{" "}
        <span className="pink">500 ₳ADA</span>!
      </p>
      <Grid columns={2} stackable centered>
        <Grid.Column>
          <div className="sneak-peak">
            <StaticImage src="../images/frozen/ice_cube_1.png" alt="frozen 1" />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className="sneak-peak">
            <StaticImage src="../images/frozen/ice_cube_2.png" alt="frozen 2" />
          </div>{" "}
        </Grid.Column>
        <Grid.Column>
          <div className="sneak-peak">
            <StaticImage src="../images/frozen/ice_cube_3.png" alt="frozen 3" />
          </div>{" "}
        </Grid.Column>
        <Grid.Column>
          <div className="sneak-peak">
            <StaticImage src="../images/frozen/ice_cube_4.png" alt="frozen 4" />
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
}
