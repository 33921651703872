import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Grid } from "semantic-ui-react";

export default function AlienOozies() {
  return (
    <div className="gallery">
      <br />
      <h1 className="section-header u">ALIEN EGG OOZ1ES</h1>
      <p className="subtitle">
        Find an OOZ1ES with an Alien Egg in its stomach & you will have the best
        chance possible to get the highest score!
        <br />
        <br />
        All of the Alien Eggs will hatch once the sale is over. There is roughly{" "}
        <span className="pink">200</span> Alien eggs in{" "}
        <span className="pink">4</span> different colors to be found. Hatched
        Alien Eggs are worth <span className="pink">2x</span> the amount of
        points as Alien Eggs.
        <br />
        <br />
        Whoever has the highest score at the end of the sale will get a 1/1 NFT
        + <span className="pink">2,000 ₳ADA</span>!
      </p>
      <Grid columns={2} stackable centered>
        <Grid.Column>
          <div className="sneak-peak">
            <StaticImage src="../images/eggs/1.png" alt="eggs 1" />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className="sneak-peak">
            <StaticImage src="../images/eggs/2.png" alt="eggs 2" />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className="sneak-peak">
            <StaticImage src="../images/eggs/3.png" alt="eggs 3" />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className="sneak-peak">
            <StaticImage src="../images/eggs/4.png" alt="eggs 4" />
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
}
