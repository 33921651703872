import React, { useState, useEffect, useRef } from "react";
import { Container, Grid } from "semantic-ui-react";
import PreviewModal from "./PreviewModal";
import ImageButton from "./ImageButton";
import SearchInput from "./SearchInput";
import oozies from "../data/oozies.json";
import bodyColor from "../data/bodyColor.json";
import face from "../data/face.json";
import food from "../data/food.json";
import hat from "../data/hat.json";
// import scores from "../data/score.json";
import bonusObject from "../data/bonusObject.json";
import laser from "../data/laser.json";
import BodyColorRarity from "./BodyColorRarity";
import FaceRarity from "./FaceRarity";
import FoodRarity from "./FoodRarity";
import HatRarity from "./HatRarity";

import redLaser from "../images/laser/red_laser.png";
import greenLaser from "../images/laser/green_laser.png";
import yellowLaser from "../images/laser/yellow_laser.png";
import blueLaser from "../images/laser/blue_laser.png";

import wind from "../images/object/window.png";
import bubble from "../images/object/bubble.png";
import snow_globe from "../images/object/snow_globe.png";
import fish_bowl from "../images/object/fish_bowl.png";
// import ScoreRange from "./ScoreRange";

// const av_scores = [
//   210, 200, 190, 180, 170, 160, 150, 140, 130, 120, 110, 100, 90, 80, 70, 60,
//   50, 40, 30, 20, 10, 0, -10, -20, -30, -40, -50, -70,
// ];

function checkAndFilter(fIds, selection, what) {
  let ids = [];
  if (selection) {
    if (what === "bodyColor") {
      ids = bodyColor[selection];
    } else if (what === "face") {
      ids = face[selection];
    } else if (what === "food") {
      ids = food[selection];
    } else if (what === "hat") {
      ids = hat[selection];
    } else if (what === "bObject") {
      ids = bonusObject[selection];
    } else if (what === "laser") {
      ids = laser[selection];
    }
    // else if (what === "scoreRange") {
    //   let min = selection[0];
    //   let max = selection[1];
    //   if (min === "") min = -70;
    //   if (max === "") max = 210;
    //   if (+min < +max) {
    //     const av = av_scores.filter((s) => s >= +min && s <= +max);
    //     av.forEach((a) => {
    //       if (scores[a] !== undefined) {
    //         ids = ids.concat(scores[a]);
    //       }
    //     });
    //   }
    // }
    fIds = ids.filter((id) => fIds.length === 0 || fIds.indexOf(id) >= 0);
  }
  return fIds;
}

let prevY = 50;

const bObj = { Window: 1, "Snow Globe": 2, Bubble: 3, "Fish Bowl": 4 };
const bImgs = [wind, snow_globe, bubble, fish_bowl];

const lObj = { Green: 1, Red: 2, Yellow: 3, Blue: 4 };
const lImgs = [greenLaser, redLaser, yellowLaser, blueLaser];

const Filters = () => {
  const [max, setMax] = useState(50);
  const [filteredIds, setFilteredIds] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    bodyColor: "",
    face: "",
    food: "",
    hat: "",
    bObject: "",
    laser: "",
  });
  const [openedImage, setOpenedImage] = useState(null);
  const loadingRef = useRef();

  useEffect(() => {
    let nfIds = [];
    Object.keys(selectedFilters).forEach((key) => {
      nfIds = checkAndFilter(nfIds, selectedFilters[key], key);
    });
    if (selectedIds.length > 0) {
      nfIds = selectedIds.filter(
        (id) => nfIds.length === 0 || nfIds.indexOf(id) >= 0
      );
    }
    if (nfIds.length > 0) {
      setFilteredIds(nfIds);
    } else {
      setMax(50);
      const noResults = Object.keys(selectedFilters).some(
        (key) => selectedFilters[key] !== ""
      );
      if (noResults) setFilteredIds([]);
      else setFilteredIds(Array.from({ length: 9999 }, (_, i) => i + 1));
    }
  }, [selectedFilters, selectedIds]);

  useEffect(() => {
    function handleObserver(entities) {
      const y = entities[0].boundingClientRect.y;
      if (prevY >= y) {
        setMax((i) => Math.min(i + 20, 9999));
      }
      prevY = y;
    }
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    });
    observer.observe(loadingRef.current);
  }, []);

  function onImageModalOpen(val) {
    setOpenedImage(val);
  }
  function onImageModalClose() {
    setOpenedImage(null);
  }
  function handleFilterChange(filter, value) {
    setSelectedFilters((sf) => ({
      ...sf,
      [filter]: value,
    }));
  }
  function handleSelection(_, selection) {
    setSelectedIds(selection.value);
  }
  function changeLaser(o) {
    if (selectedFilters.laser === o) {
      setSelectedFilters((sf) => ({ ...sf, laser: "" }));
    } else {
      setSelectedFilters((sf) => ({ ...sf, laser: o }));
    }
  }
  function changeBObject(o) {
    if (selectedFilters.bObject === o) {
      setSelectedFilters((sf) => ({ ...sf, bObject: "" }));
    } else {
      setSelectedFilters((sf) => ({ ...sf, bObject: o }));
    }
  }

  return (
    <>
      <Container text style={{ paddingTop: 20 }}>
        <h1 className="section-header u">EXPLORE</h1>
        <SearchInput
          selectedOptions={selectedIds}
          onSelected={handleSelection}
        />
        <Grid stackable columns={2} style={{ marginTop: "1.1rem" }}>
          <Grid.Column>
            <BodyColorRarity
              rarity={selectedFilters.bodyColor}
              onChange={handleFilterChange}
            />
          </Grid.Column>
          <Grid.Column>
            <FaceRarity
              rarity={selectedFilters.face}
              onChange={handleFilterChange}
            />
          </Grid.Column>
          <Grid.Column>
            <FoodRarity
              rarity={selectedFilters.food}
              onChange={handleFilterChange}
            />
          </Grid.Column>
          <Grid.Column>
            <HatRarity
              rarity={selectedFilters.hat}
              onChange={handleFilterChange}
            />
          </Grid.Column>
          {/* <Grid.Column>
            <ScoreRange
              value={selectedFilters.scoreRange}
              onChange={handleFilterChange}
            />
          </Grid.Column> */}
          <Grid.Column>
            <p className="label">Lasers</p>
            <div className="four-button">
              {Object.keys(lObj).map((key, idx) => (
                <button
                  key={idx}
                  onClick={() => changeLaser(lObj[key])}
                  className={
                    selectedFilters.laser === lObj[key] ? "active" : ""
                  }
                >
                  <img src={lImgs[idx]} alt={key} />
                  <span>{key}</span>
                </button>
              ))}
            </div>
          </Grid.Column>
          <Grid.Column>
            <p className="label">Bonus Object</p>
            <div className="four-button bo">
              {Object.keys(bObj).map((key, idx) => (
                <button
                  key={idx}
                  onClick={() => changeBObject(bObj[key])}
                  className={
                    selectedFilters.bObject === bObj[key] ? "active" : ""
                  }
                >
                  <img src={bImgs[idx]} alt={key} />
                  <span>{key}</span>
                </button>
              ))}
            </div>
          </Grid.Column>
        </Grid>
        <p className="results">
          {filteredIds.length} {filteredIds.length > 1 ? "results" : "result"}
        </p>
      </Container>
      <div className="resultContainer">
        <div className="imgContainer">
          {filteredIds.slice(0, max).map((val) => (
            <ImageButton
              key={val}
              value={val}
              onClick={() => onImageModalOpen(oozies[val - 1])}
            />
          ))}
        </div>
      </div>
      <div
        ref={loadingRef}
        className="ui active centered inline loader"
        style={{
          display:
            filteredIds.length === 0 || filteredIds.length < max
              ? "none"
              : "block",
        }}
      ></div>
      <PreviewModal openedImage={openedImage} onClose={onImageModalClose} />
    </>
  );
};

export default Filters;
