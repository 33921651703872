import React, { useState } from "react";
import { Button, Icon, Modal, TransitionablePortal } from "semantic-ui-react";
import Clipboard from "react-clipboard.js";

const prices = [
  {
    key: 1,
    total: "10 ₳ADA",
  },
  {
    key: 2,
    total: "20 ₳ADA",
  },
  {
    key: 3,
    total: "30 ₳ADA",
  },
  {
    key: 4,
    total: "40 ₳ADA",
  },
  {
    key: 5,
    total: "50 ₳ADA",
  },
  {
    key: 6,
    total: "60 ₳ADA",
  },
  {
    key: 7,
    total: "70 ₳ADA",
  },
  {
    key: 8,
    total: "80 ₳ADA",
  },
  {
    key: 9,
    total: "90 ₳ADA",
  },
  {
    key: 10,
    total: "100 ₳ADA",
  },
];

export default function BuyModal({ open, onClose }) {
  const [copied, setCopied] = useState(false);
  return (
    <TransitionablePortal open={open} transition={{ animation: "fly up" }}>
      <Modal size="mini" open={true} onClose={onClose} dimmer="blurring">
        <Modal.Content
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0.5em",
          }}
        >
          <h1 style={{ color: "#22ba1a", fontSize: 26 }}>9676 OOZ1ES Left!</h1>
          <Button
            positive
            icon
            labelPosition="right"
            onClick={() => setCopied(true)}
            as={Clipboard}
            data-clipboard-text="addr1v9pq27h0vexrr8ml40fegpv3f2gx02hc9eapqcz29uuv4sglnnskj"
            title="Click to Copy"
            style={{ wordBreak: "break-word", margin: 0 }}
          >
            addr1v9pq27h0vexrr8ml40fegpv3f2gx02hc9eapqcz29uuv4sglnnskj
            <Icon name={copied ? "check" : "copy"} />
          </Button>
          <p className="modal-description">
            Please send<span className="pink"> 10 ₳ADA </span>to the address
            above to receive<span className="pink"> 1 </span>OOZ1ES, to receive
            more OOZ1ES please send the amount listed on the chart below.
          </p>
          <table className="price-table">
            <thead>
              <tr>
                <th>Qty.</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {prices.map((price) => (
                <tr
                  key={price.key}
                  style={{ color: price.key === 10 ? "#ff10f0" : "" }}
                >
                  <td>{price.key}</td>
                  <td>{price.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Content>
      </Modal>
    </TransitionablePortal>
  );
}
