import React from "react";

export default function ImageButton({ onClick, value }) {
  const image =
    "https://3d.ooz1es.com/pictures/ooz1es3d_" +
    value.toString().padStart(4, "0") +
    ".png";
  const token = "OOZ1ES3D#" + value;
  return (
    <button className="imgButton" onClick={onClick}>
      <img src={image} alt={"img" + value} className="oozie" />
      <strong>{token}</strong>
    </button>
  );
}
