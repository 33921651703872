import React from "react";
import { Modal, Image, TransitionablePortal } from "semantic-ui-react";

export default function PreviewModal({ openedImage, onClose }) {
  const image =
    "https://3d.ooz1es.com/pictures/ooz1es3d_" +
    openedImage?.i?.toString().padStart(4, "0") +
    ".png";
  const label = `OOZ1ES3D#${openedImage?.i}`;
  return (
    <TransitionablePortal
      open={openedImage !== null}
      transition={{ animation: "fly up" }}
    >
      <Modal
        size="mini"
        open={true}
        dimmer="blurring"
        onClose={onClose}
        className="previewModal"
      >
        <Modal.Content
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1 className="m-header">{label}</h1>
          <div className="oozie-container">
            <a href={image} download>
              <Image
                src={image}
                alt={label}
                layout="fixed"
                style={{
                  width: 320,
                  height: 320,
                  borderRadius: 10,
                }}
              />
            </a>
          </div>
          <table>
            <tbody>
              <tr>
                <th>Body color</th>
                <td>:</td>
                <td>{openedImage?.b}</td>
              </tr>
              <tr>
                <th>Hat</th>
                <td>:</td>
                <td>{openedImage?.h}</td>
              </tr>
              <tr>
                <th>Face</th>
                <td>:</td>
                <td>{openedImage?.v}</td>
              </tr>
              <tr>
                <th>Food</th>
                <td>:</td>
                <td>{openedImage?.f}</td>
              </tr>
              <tr>
                <th>Score</th>
                <td>:</td>
                <td>{openedImage?.s}</td>
              </tr>
            </tbody>
          </table>
        </Modal.Content>
      </Modal>
    </TransitionablePortal>
  );
}
