import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import food from "../data/drops/foodDrops.json";

export default function FoodRarity({ rarity, onChange }) {
  const [text, setText] = useState("");
  useEffect(() => {
    if (rarity === "") setText("");
  }, [rarity]);
  function handleChange(_, { value }) {
    if (value === "") setText("");
    onChange("food", value);
  }
  return (
    <Dropdown
      placeholder="Food"
      search
      selection
      fluid
      clearable
      basic
      selectOnBlur={false}
      options={food}
      onChange={handleChange}
      value={rarity}
      text={text}
      style={{ marginBottom: 15 }}
    />
  );
}
