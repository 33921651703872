import React, { useState, useEffect } from "react";
import { Container, Icon } from "semantic-ui-react";
import Header from "../components/Header";
import Faq from "../components/FAQ";
import About from "../components/About";
import BuyModal from "../components/BuyModal";
import MetaTags from "../components/MetaTags";
import Roadmap from "../components/Roadmap";
import ExternalLinks from "../components/ExternalLinks";
import FrozenOozies from "../components/FrozenOozies";
import AlienOozies from "../components/AlienOozies";
import TurtleOozies from "../components/TurtleOozies";
import Filters from "../components/Filters";

const IndexPage = () => {
  const [openedBuyModal, setOpenedBuyModal] = useState(null);

  useEffect(() => {
    const styles =
      "color: yellow;background: black;font-size: 18px;border-left: 1px solid red;border-right: 1px solid red;padding: 10px";
    console.log(
      "%cHi Stranger!                          \n" +
        "Creator: https://twitter.com/diicasses\n" +
        "Dev: https://wa.me/+8801861590250 ",
      styles
    );
  }, []);

  return (
    <>
      <MetaTags />
      <Header onBuyButtonClicked={() => setOpenedBuyModal(true)} />
      <div className="socials">
        <a href="https://www.twitter.com/ooz1es">
          <Icon name="twitter" />
        </a>
        <a href="https://discord.gg/jrBWccuqQS">
          <Icon name="discord" />
        </a>
        <a href="https://www.instagram.com/ooz1es">
          <Icon name="instagram" />
        </a>
        <a href="https://www.facebook.com/ooz1es">
          <Icon name="facebook" />
        </a>
      </div>
      <Container text style={{ marginTop: 20 }}>
        <About />
        <a
          href="https://cardanoscan.io/tokenPolicy/b6965365632a18869e36ece41ae3a76835cf84180dde4f18857c1aea"
          className="policy"
        >
          <strong>Policy ID</strong>
          <p>b6965365632a18869e36ece41ae3a76835cf84180dde4f18857c1aea</p>
        </a>
        <Faq />
        <ExternalLinks />
      </Container>
      <Roadmap />
      <Container text style={{ marginTop: 20 }}>
        <FrozenOozies />
        <AlienOozies />
        <TurtleOozies />
      </Container>
      <Filters />
      <BuyModal
        open={openedBuyModal}
        onClose={() => setOpenedBuyModal(false)}
      />
    </>
  );
};

export default IndexPage;
