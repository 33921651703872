import React, { useState } from "react";
import { Accordion, Button, Icon, Transition } from "semantic-ui-react";

const data = [
  {
    title: "How can I buy a 3D OOZ1ES?",
    description:
      '<p>Click the "BUY NOW" button it will give you a Cardano address to send payment to. Send the exact amount, for <span class="pink">1</span> OOZ1ES send <span class="pink">10 ₳ADA</span>, for <span class="pink">10</span> OOZ1ES send <span class="pink">100 ₳ADA</span>. Don\'t send from an exchange or your OOZ1ES maybe lost forever!</p><br/><p>We recommend <a href="https://eternl.io/">ETERNL wallet</a>.</p><br/><p>If you have any other questions please visit our Discord server to ask questions: <a href="https://discord.gg/crVa3vjmr2">https://discord.gg/crVa3vjmr2</a>.</p>',
  },
  // {
  //   title: "How will the 3D OOZ1ES Sale work?",
  //   description: `<p>We will do <span class="pink">4</span> drops of <span class="pink">2,222</span> OOZ1ES per drop. PIXEL OOZ1ES hodlers will get whitelist to have a chance to buy 3D OOZ1ES first!</p>
  //     <table class="ui table sale">
  //       <tr>
  //         <td>1 &mdash; 2,222</td><td>10 ₳ADA</td>
  //       </tr>
  //       <tr>
  //         <td>2,223 &mdash; 4,444</td><td>12 ₳ADA</td>
  //       </tr>
  //       <tr>
  //         <td>4,445 &mdash; 6,666</td><td>16 ₳ADA</td>
  //       </tr>
  //       <tr>
  //         <td>6,667 &mdash; 8,888</td><td>20 ₳ADA</td>
  //       </tr>
  //     </table>`,
  // },
  {
    title: "I got an Alien Egg OOZ1ES what does this mean?",
    description:
      "<p>If you got one of the Alien Eggs this means that it will hatch into an alien in the future after the sale is over! You don't need to do anything but hold it till it hatches! When your Alien Egg hatches it will be worth twice as many points as the Alien Egg!</p>",
  },
  {
    title: "What does Score mean?",
    description:
      '<p>Whoever has the highest score at the end of the sale will win <span class="pink">2,000 ₳ADA</span> & an exclusive 1/1 CNFT from us. Its also a way to determine how rare your 3D OOZ1ES is!</p>',
  },
  {
    title: "What does the future hold for OOZ1ES?",
    description:
      '<p>You can look at OOZ1ES as the Pokémon of the Anti.biz universe. That the <span class="pink">12</span> main characters will use to battle each other. Our long term goal is to create <span class="pink">999</span> unique characters and eventually release trading cards/battle card type game which will feature the main characters, Space Babez and OOZ1ES!</p>',
  },
  {
    title: "Are 3D OOZ1ES a good investment?",
    description:
      "<p>That is ultimately a decision for you to make. If you like the art and its within your budget then you should grab one if it makes you happy. We don't recommend buying our NFTS expecting to get rich or make a quick flip. We have a long road ahead of us before we start becoming more mainstream and more people start to notice what we're building over here. We are still a very underground project that not many know about.</p><p>OOZ1ES is an integral part of the Anti.biz universe which will be an ongoing project which will eventually lead to comic books, toys, games and more!</p>",
  },
  {
    title: "What special perks do I get for hodling your NFT?",
    description:
      'Everyone that holds an NFT created by <a href="https://anti.biz" target="_blank" rel="noreferrer">Anti.biz</a> will be recognize as part of the community and receive exclusive access to private content/products and receive discounts towards new things we create! We are always on the look out for new ways to reward our community for supporting us.',
  },
  {
    title: "Who is the team that created OOZ1ES?",
    description:
      'The team is made up of the creator <a href="https://www.twitter.com/diicasses" target="_blank" rel="noreferrer">@diicasses</a>, coding <a href="#">Equinox#1175</a> (Discord), <a href="https://easycnft.art/" target="_blank" rel="noreferrer">backend sales</a>, and the <a id="wd" target="_blank" rel="noreferrer" href="https://wa.me/+8801861590250">web developer</a>. Which is all a part of the business <a href="https://anti.biz" target="_blank" rel="noreferrer">Anti.biz</a>',
  },
  {
    title: "What can I do with my 3D OOZ1ES?",
    description:
      "You are free to do anything with them under a non-exclusive license.",
  },
  {
    title: "How do I get involved?",
    description:
      'Head on over to <a href="https://discord.gg/jrBWccuqQS" target="_blank"  rel="noreferrer">Discord</a> & <a href="https://www.twitter.com/ooz1es" target="_blank"  rel="noreferrer">Twitter</a> to jump into the conversation & share your ideas!~',
  },
];

export default function FAQ() {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  function toggleVisibility() {
    setVisible((v) => !v);
  }

  function handleClick(_, titleProps) {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  }

  return (
    <div>
      <Button
        fluid
        onClick={toggleVisibility}
        className={"faq-button" + (visible ? " opened" : "")}
        basic
      >
        <span>FAQ</span>
        <div className="trw">
          <Transition
            visible={visible}
            animation="vertical flip"
            duration={200}
          >
            <Icon
              color={visible ? "green" : "grey"}
              name="angle up"
              size="large"
            />
          </Transition>
        </div>
        <div className="trw">
          <Transition
            visible={!visible}
            animation="vertical flip"
            duration={200}
          >
            <Icon name="angle down" size="large" />
          </Transition>
        </div>
      </Button>
      <Transition
        visible={visible}
        animation="slide down"
        duration={400}
        unmountOnHide
      >
        <Accordion styled fluid>
          {data.map((d, idx) => (
            <React.Fragment key={idx}>
              <Accordion.Title
                active={activeIndex === idx}
                index={idx}
                onClick={handleClick}
                style={{ fontSize: 22, color: "#444" }}
              >
                <Icon name="dropdown" color="grey" />
                {d.title}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === idx}>
                <Transition.Group animation="slide down" duration={300}>
                  {activeIndex === idx && (
                    <div
                      style={{ color: "#444", fontSize: 18 }}
                      dangerouslySetInnerHTML={{ __html: d.description }}
                    />
                  )}
                </Transition.Group>
              </Accordion.Content>
            </React.Fragment>
          ))}
        </Accordion>
      </Transition>
    </div>
  );
}
