import React from "react";
import { TransitionGroup, Transition, Icon, Button } from "semantic-ui-react";

export default function ExternalLinks() {
  const [open, setOpen] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  function handleClick() {
    setOpen(!open);
    setVisible(!visible);
  }
  return (
    <div style={{ marginTop: 20 }}>
      <Button
        fluid
        onClick={handleClick}
        className={"faq-button" + (visible ? " opened" : "")}
        basic
      >
        <span>External Links</span>
        <div className="trw">
          <Transition
            visible={visible}
            animation="vertical flip"
            duration={200}
          >
            <Icon
              color={visible ? "green" : "grey"}
              name="angle up"
              size="large"
            />
          </Transition>
        </div>
        <div className="trw">
          <Transition
            visible={!visible}
            animation="vertical flip"
            duration={200}
          >
            <Icon name="angle down" size="large" />
          </Transition>
        </div>
      </Button>
      <TransitionGroup animation="slide down" duration={500}>
        {open && (
          <ul>
            <li>
              We are now Verified on&mdash;
              <ul>
                <li>
                  <a
                    href="https://www.jpg.store/collection/OOZ1ES3D"
                    target="_blank"
                    rel="noreferrer"
                  >
                    JPG Store
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        )}
      </TransitionGroup>
    </div>
  );
}
