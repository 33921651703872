import React from "react";
import { Helmet } from "react-helmet";

const banner = "https://3d.ooz1es.com/o_logo_meta.png";

export default function MetaTags() {
  return (
    <Helmet>
      <html lang="en" />
      <title>OOZ1ES3D - Ür favorite toilet dwelling creatures are back.</title>
      <link rel="icon" href={banner} type="image/png" />
      <meta
        name="title"
        content="OOZ1ES3D - Ür favorite toilet dwelling creatures are back."
      />
      <meta
        name="description"
        content="OOZ1ES 3D is the newest NFT created to live forever on the Cardano Blockchain. There is a total of 9,999 unique OOZ1ES randomly generated from a selection of 251 variations in 5 rarity categories w/ 156,028,055,040 billion possible combos the whacky combinations are endless."
      />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://3d.ooz1es.com/" />
      <meta
        property="og:title"
        content="OOZ1ES3D - Ür favorite toilet dwelling creatures are back!"
      />
      <meta
        property="og:description"
        content="OOZ1ES 3D is the newest NFT created to live forever on the Cardano Blockchain. There is a total of 9,999 unique OOZ1ES randomly generated from a selection of 251 variations in 5 rarity categories w/ 156,028,055,040 billion possible combos the whacky combinations are endless."
      />
      <meta property="og:image" content={banner} />
      <meta property="twitter:url" content="https://3d.ooz1es.com/" />
      <meta property="twitter:image" content={banner} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:title"
        content="OOZ1ES3D - Your favorite toilet dwelling creatures are back!"
      />
      <meta
        property="twitter:description"
        content="OOZ1ES 3D is the newest NFT created to live forever on the Cardano Blockchain. There is a total of 9,999 unique OOZ1ES randomly generated from a selection of 251 variations in 5 rarity categories w/ 156,028,055,040 billion possible combos the whacky combinations are endless."
      />
    </Helmet>
  );
}
