import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import hats from "../data/drops/hatsDrops.json";

export default function HatRarity({ rarity, onChange }) {
  const [text, setText] = useState("");
  useEffect(() => {
    if (!rarity) setText("");
  }, [rarity]);
  function handleChange(_, { value }) {
    console.log(value === "", !value);
    if (!value) setText("");
    onChange("hat", value);
  }
  return (
    <Dropdown
      placeholder="Hat"
      search
      selection
      fluid
      clearable
      basic
      selectOnBlur={false}
      options={hats}
      onChange={handleChange}
      value={rarity}
      text={text}
      style={{ marginBottom: 15 }}
    />
  );
}
