import "@splidejs/splide/dist/css/splide.min.css";
import React, { useState, useEffect } from "react";
import Splide from "@splidejs/splide";
import { Transition, Icon, Container, Button } from "semantic-ui-react";

import th1 from "../images/thermometer/0.png";
import th2 from "../images/thermometer/1.png";
import th3 from "../images/thermometer/2.png";
import th4 from "../images/thermometer/3.png";
import th5 from "../images/thermometer/4.png";
import th6 from "../images/thermometer/5.png";
import th7 from "../images/thermometer/6.png";
import th8 from "../images/thermometer/7.png";
import th9 from "../images/thermometer/8.png";
import th10 from "../images/thermometer/9.png";
import th11 from "../images/thermometer/10.png";

const thermometers = [th1, th2, th3, th4, th5, th6, th7, th8, th9, th10, th11];

const data = [
  "Launch sale & build community. JPG.store verification",
  "CNFT.TOOLS listing, CardanoScan.io advert, OPENCNFT.io advert",
  "Marketing increases Twitter, Facebook, CNFT market place ads.",
  "Community Giveaways (giveaway 3D OOZ1ES on Twitter daily/weekly throughout sale!)",
  "OOZ1ES advertisement 1/1 NFT giveaways. (Be the 1,111 mint, 2,222 mint, 3,333 mint, 4,444 mint, 5,555 mint, 6,666 mint, 7,777 mint, 8,888 mint, 9,999 mint and receive a special 1/1 artwork!)",
  "OOZ1ES 3D Toy Box NFT #1 100/100 New unique character Q4 2022",
  "OOZ1ES 3D Toy Box NFT #2 100/100 New unique character Q1 2023",
  "OOZ1ES 3D Toy Box NFT #3 100/100 New unique character Q1 2023",
  "OOZ1ES Comic Book Issue #1 Q1 2023",
  "Space Babez 2 100% on-chain generative NFT project. Q4 2022",
  "OOZ1ES Universe 100% on-chain NFT project. (999 unique/original characters) Q3-Q4 2023",
];

const valData = [96, 92, 84, 75, 67, 58, 50, 41, 33, 16];

let splide = null;

export default function Roadmap() {
  const [range, setRange] = useState(0);
  const [img, setImg] = useState(0);
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  function toggleVisibility() {
    setOpen(!open);
    setVisible(!visible);
  }
  function initSplide() {
    splide = new Splide(".splide", {
      start: 0,
      arrows: false,
      direction: "ttb",
      height: 600,
      heightRatio: 0.33,
      pagination: false,
      perPage: 3,
      focus: 0,
      keyboard: false,
      trimSpace: false,
      easing: "cubic-bezier(.69,.04,.32,.97)",
    });
    splide.mount();
    splide.on("moved", function () {
      setImg(splide.index);
      setTimeout(() => {
        setRange(valData[splide.index]);
      }, 300);
    });
  }
  useEffect(() => {
    initSplide();
  }, []);
  function handleChange(e) {
    const v = e.target.value;
    let val;
    if (v >= 96) {
      val = 10;
    } else if (v >= 92) {
      val = 9;
    } else if (v >= 84) {
      val = 8;
    } else if (v >= 75) {
      val = 7;
    } else if (v >= 67) {
      val = 6;
    } else if (v >= 58) {
      val = 5;
    } else if (v >= 50) {
      val = 4;
    } else if (v >= 41) {
      val = 3;
    } else if (v >= 33) {
      val = 2;
    } else if (v >= 19) {
      val = 1;
    } else {
      val = 0;
    }
    if (splide) {
      splide.go(val);
    }
  }
  function goTo(idx) {
    if (splide) {
      setImg(idx);
      setRange(valData[idx]);
      splide.go(idx);
    } else {
      initSplide();
    }
  }
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Container text style={{ margin: "10px 0" }}>
        <Button
          fluid
          onClick={toggleVisibility}
          className={"faq-button" + (visible ? " opened" : "")}
          basic
        >
          <span>Roadmap</span>
          <div className="trw">
            <Transition
              visible={visible}
              animation="vertical flip"
              duration={200}
            >
              <Icon
                color={visible ? "green" : "black"}
                name="angle up"
                size="large"
              />
            </Transition>
          </div>
          <div className="trw">
            <Transition
              visible={!visible}
              animation="vertical flip"
              duration={200}
            >
              <Icon name="angle down" size="large" />
            </Transition>
          </div>
        </Button>
      </Container>
      <div
        className="roadmap-container"
        style={{ display: open ? "flex" : "none" }}
      >
        <div className="thermometer-container">
          <img
            src={thermometers[img]}
            alt="thermometer"
            className="thermometer"
          />
          <input
            type="range"
            min={0}
            max={100}
            step={1}
            value={range}
            onChange={handleChange}
          />
        </div>
        <div className="splide text-container">
          <div className="splide__track">
            <div className="splide__list">
              {data.map((d, i) => (
                <div
                  key={i}
                  className="splide__slide"
                  aria-hidden="true"
                  onClick={() => goTo(i)}
                >
                  <div style={{ textAlign: "center" }}>
                    <span>{d}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
